import React, { Component, useState } from "react";
import { Link } from "gatsby";
import Collapse from 'react-bootstrap/Collapse';
// import scrollTo from 'gatsby-plugin-smoothscroll';
// import BackgroundImage from 'gatsby-background-image'
import Layout from "../components/layout";

import SEO from "../components/seo";
import Carousel from 'react-bootstrap/Carousel';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import SimpleModal from '../components/utilities/SimpleModal';

import './index.scss';

import ImageWhoDoWeThink from "../components/images/imageWhoDoWeThink";

import downarrow from "../images/down-arrow.svg";
import stuffarrow from "../images/stuff-arrow.svg";
import videoPlay from "../images/play-button.svg";
import backgroundVideo from "../videos/merit-mile-home-page.mp4";
import workItem2 from '../images/w-citrix.webp';
import workItem3 from '../images/w-dxc.webp';
import workItem4 from '../images/w-hearst.webp';
import workItem5 from '../images/w-equifax.webp';
import workItem7 from '../images/w-bocajetslacrosse.webp';
import workItem8 from '../images/w-lifesync.webp';
import brandTop from '../images/brand-top.webp';
import brandBottom from '../images/brand-bottom.webp';
import iconAdvertising from '../images/icon-advertising.svg';
import iconInteractive from '../images/icon-interactive.svg';
import iconPR from '../images/icon-pr.svg';
import iconSocial from '../images/icon-social.svg';
import iconResearch from '../images/icon-research.svg';


import brhsBg from '../images/bg-boca-raton-historical-society.webp';
import brhsLogo from '../images/logo-boca-raton-historical-society.webp';
import citrixBg from '../images/bg-citrix.webp';
import citrixLogo from '../images/logo-citrix.webp';
import audlBg from '../images/bg-audl.webp';
import audlLogo from '../images/logo-audl.webp';
import viaMiznerBg from '../images/bg-via-mizner.webp';
import viaMiznerLogo from '../images/logo-via-mizner.webp';
import lifeSyncBg from '../images/bg-lifesync.webp';
import lifeSyncLogo from '../images/logo-lifesync.webp';
import microsoftBg from '../images/bg-microsoft.webp';
import microsoftLogo from '../images/logo-microsoft.webp';
import waltersBg from '../images/bg-wolters.webp';
import waltersLogo from '../images/logo-wolters-kluwer.webp';
import iridiumBg from '../images/bg-iridium.webp';
import iridiumLogo from '../images/logo-iridium.webp';

const workItems = [
  {
      name:'Citrix',
      type:'ADVERTISING',
      image:workItem2,
      url:'/case-studies/citrix/'
  },
  {
      name:'Hearst',
      type:'RESEARCH',
      image:workItem4,
      url:'/case-studies/black-book/'
  },
  {
      name:'HPE',
      type:'SOCIAL',
      image:workItem3,
      url:'/case-studies/hpe/'
  },
  {
      name:'Boca Jets Lacrosse',
      type:'BRANDING',
      image:workItem7,
      url:'/case-studies/boca-jets-lacrosse/'
  },
  {
      name:'Equifax',
      type:'PUBLIC RELATIONS',
      image:workItem5,
      url:'/case-studies/equifax/'
  },
  {
      name:'LifeSync',
      type:'INTERACTIVE',
      image:workItem8,
      url:'/case-studies/lifesync/'
  }
];

class WorkItem extends Component {
    render() {
        return (
            <Link to={this.props.url}>
                <div className="workItem" style={ {backgroundImage: `url(${this.props.image})`} }>
                    <div className="text-wrapper">
                    <h2>{this.props.name}</h2>
                    <p>{this.props.type}</p>
                    <div className="bar">&nbsp;</div>
                    </div>
                </div>
            </Link>
        );
    }
}

class Video extends Component {
  constructor(props) {
      super(props);
      this.modalRef = React.createRef();
      this.openModal = this.openModal.bind(this);
  };
  openModal() {
      this.modalRef.current.handleOpen();
  };
    render() {
        return (
            <div className="container-fluid p-0 video">
                    <video loop muted autoPlay playsInline>
                        <source src={backgroundVideo} type="video/mp4"  />
                    </video>
                    <div className="banner">
                        <div className="banner-text">
                            <div className="container">
                                <div className="row no-gutters align-items-center">
                                    <div className="hidden-sm-down col-md-1">
                                        <AnchorLink href="#about" offset='100'><img className="down-arrow" src={downarrow} width="41" alt="" /></AnchorLink>
                                    </div>
                                    <div className="col-md-9">
                                        <h1 className="top-copy">Building brands, modernizing marketing &amp; driving sales.</h1>
                                        <h2 className="work-copy"><AnchorLink href="#AdvertisingItem" offset='100'>Marketing</AnchorLink>, <AnchorLink href="#PublicRelationsItem" offset='100'>PR</AnchorLink> &amp; <AnchorLink href="#AdvertisingItem" offset='100'>Advertising Agency</AnchorLink><br />Boca Raton, FL USA</h2>
                                    </div>
                                    <div className="col-md-2">
                                        <img src={videoPlay} onClick={() => this.openModal()} id="playVideo" alt=""/>
                                        <SimpleModal videoSRC='f7FaGLdtgt0' innerRef={this.modalRef}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<img className="img-fluid" src={videoImage} alt=""/>*/}
            </div>
        );
    }
}

class SlideShow extends Component {
    constructor(props) {
        super(props);
    };
    render() {
        return (
            <div className="slideshow">
                <Carousel>
                    <Carousel.Item>
                        <a href="/case-studies/iridium/">
                            <img
                                className="d-block w-100"
                                src={iridiumBg}
                                alt="Iridium"
                            />
                            <Carousel.Caption>
                                <img
                                    className="slide-logo"
                                    src={iridiumLogo}
                                    alt="Iridium Logo"
                                    style={{ maxWidth: '120px' }}
                                />
                                <h3>B2C Advertising</h3>
                                <p><span className="underlined">Learn More</span></p>
                            </Carousel.Caption>
                        </a>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="/case-studies/boca-raton-historical-society/">
                            <img
                                className="d-block w-100"
                                src={brhsBg}
                                alt="Boca Raton Historical Society"
                            />
                            <Carousel.Caption>
                                <img
                                    className="slide-logo"
                                    src={brhsLogo}
                                    alt="Boca Raton Historical Society"
                                    style={{ maxWidth: '120px' }}
                                />
                                <h3>Branding</h3>
                                <p><span className="underlined">Learn More</span></p>
                            </Carousel.Caption>
                        </a>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="/case-studies/citrix-chromeos-vertical-marketing/">
                            <img
                                className="d-block w-100"
                                src={citrixBg}
                                alt="Citrix and Google Cloud"
                            />
                            <Carousel.Caption>
                                <img
                                    className="slide-logo"
                                    src={citrixLogo}
                                    alt="Citrix and Google Cloud"
                                    style={{ maxWidth: '100px' }}
                                />
                                <h3>B2B Marketing</h3>
                                <p><span className="underlined">Learn More</span></p>
                            </Carousel.Caption>
                        </a>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="/case-studies/audl-pr/">
                            <img
                                className="d-block w-100"
                                src={audlBg}
                                alt="AUDL"
                            />
                            <Carousel.Caption>
                                <img
                                    className="slide-logo"
                                    src={audlLogo}
                                    alt="AUDL"
                                    style={{ maxWidth: '100px' }}
                                />
                                <h3>Sports Marketing</h3>
                                <p><span className="underlined">Learn More</span></p>
                            </Carousel.Caption>
                        </a>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="/portfolio/media/advertising/via-mizner-mandarin-oriental-boca-raton/">
                            <img
                                className="d-block w-100"
                                src={viaMiznerBg}
                                alt="Via Mizaner Golf & City Club Mandarin Oriental Boca Raton"
                            />
                            <Carousel.Caption>
                                <img
                                    className="slide-logo"
                                    src={viaMiznerLogo}
                                    alt="Via Mizaner Golf & City Club Mandarin Oriental Boca Raton"
                                    style={{ maxWidth: '250px' }}
                                />
                                <h3>Hospitality Marketing</h3>
                                <p><span className="underlined">Learn More</span></p>
                            </Carousel.Caption>
                        </a>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="/case-studies/lifesync/">
                            <img
                                className="d-block w-100"
                                src={lifeSyncBg}
                                alt="LifeSync"
                            />
                            <Carousel.Caption>
                                <img
                                    className="slide-logo"
                                    src={lifeSyncLogo}
                                    alt="LifeSync"
                                    style={{ maxWidth: '130px' }}
                                />
                                <h3>Healthcare Marketing</h3>
                                <p><span className="underlined">Learn More</span></p>
                            </Carousel.Caption>
                        </a>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="/case-studies/microsoft-nuance/">
                            <img
                                className="d-block w-100"
                                src={microsoftBg}
                                alt="Microsoft"
                            />
                            <Carousel.Caption>
                                <img
                                    className="slide-logo"
                                    src={microsoftLogo}
                                    alt="Microsoft"
                                    style={{ maxWidth: '130px' }}
                                />
                                <h3>B2B Lead Generation</h3>
                                <p><span className="underlined">Learn More</span></p>
                            </Carousel.Caption>
                        </a>
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="/services/public-relations/">
                            <img
                                className="d-block w-100"
                                src={waltersBg}
                                alt="Walters Kluwer"
                            />
                            <Carousel.Caption>
                                <img
                                    className="slide-logo"
                                    src={waltersLogo}
                                    alt="Walters Kluwer"
                                    style={{ maxWidth: '165px' }}
                                />
                                <h3>B2B Public Relations</h3>
                                <p><span className="underlined">Learn More</span></p>
                            </Carousel.Caption>
                        </a>
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

function CollapseAdvertising() {
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);
  
    return (
      <>
        <div className="itemWrapperDiv">
            <hr />
            <div
                onClick={() => {
                    setOpen(!open);
                    setActive(!active);
                }}
                aria-controls="advertisingBody"
                aria-expanded={open}
                data-active={active}
                className="stuffItem col-12"
                id="AdvertisingItem">
                <img src={iconAdvertising} alt="" />
                <div className="title">Advertising</div>
                <div className="body">Work directly with talented people who are dedicated to creating your message, your campaign, and your path to success</div>
                <div className="btn btn-expand"></div>
            </div>
            <Collapse in={open}>
                <div className="collapse" id="advertisingBody">
                    <div className="menu-body">
                        <div className="stuff-collapse-body row no-gutters">
                            <div className="col-md-6">
                                <ul>
                                    <li>Branding &amp; Identity Design</li>
                                    <li>End-to-End Consumer and B2B Campaigns</li>
                                    <li>Print, Broadcast, Video &amp; Digital Production</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul>
                                    <li>Photo Shoots, Copywriting, Content Marketing</li>
                                    <li>Channel Marketing &amp; Lead Generation</li>
                                    <li>Direct Marketing &amp; Awareness Campaigns</li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <p>Trophies are nice, we sure have a bunch of them. But, our triumphs are measured by your results. We provide traditional and digital advertising services for start-ups and Fortune 500 brands in a variety of industries. In short, advertising that’s rooted in science and fueled by art—for both awareness and demand generation—and what we like to call #MileStyle.</p>
                                <a href="/case-studies/citrix/"><div className="btn btn-white-outline">View Case Study</div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
      </>
    );
}

function CollapseInteractive() {
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);
  
    return (
      <>
        <div className="itemWrapperDiv">
            <hr />
            <div
                onClick={() => {
                    setOpen(!open);
                    setActive(!active);
                }}
                aria-controls="interactiveBody"
                aria-expanded={open}
                data-active={active}
                className="stuffItem col-12"
                id="InteractiveItem">
                <img src={iconInteractive} alt="" />
                <div className="title">Interactive</div>
                <div className="body">Stay ahead of the technology curve with purpose-built online experiences across web design and application development</div>
                <div className="btn btn-expand"></div>
            </div>
            <Collapse in={open}>
                <div className="collapse" id="interactiveBody">
                    <div className="menu-body">
                        <div className="stuff-collapse-body row no-gutters">
                            <div className="col-md-6">
                                <ul>
                                    <li>Web Design (UI &amp; UX)</li>
                                    <li>Web Application Development</li>
                                    <li>Email Marketing, Banner Ads &amp; Rich Media Campaigns</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul>
                                    <li>SEO, SEM &amp; Google Analytics/AdWords Certified</li>
                                    <li>CRM &amp; CMS Integration</li>
                                    <li>Video, Augmented &amp; Virtual Reality</li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <p>The internet is transforming 24/7 - driven by forces both fickle and fierce, through trends and technology. We’ve seen and done it all, from email campaigns to enterprise-level platforms, including Salesforce.com and Pardot integration. We’ve mastered programming languages and best-practices that embrace the internet of today and are prepared the internet of tomorrow.</p>
                                <a href="/case-studies/anywhereapp"><div className="btn btn-white-outline">View Case Study</div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
      </>
    );
}

function CollapsePublicRelations() {
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);
  
    return (
      <>
        <div className="itemWrapperDiv">
            <hr />
            <div
                onClick={() => {
                    setOpen(!open);
                    setActive(!active);
                }}
                aria-controls="publicRelationsBody"
                aria-expanded={open}
                data-active={active}
                className="stuffItem col-12"
                id="PublicRelationsItem">
                <img src={iconPR} alt="" />
                <div className="title">Public Relations</div>
                <div className="body">Extend your voice and your editorial thought leadership vision to targeted local or global audiences</div>
                <div className="btn btn-expand"></div>
            </div>
            <Collapse in={open}>
                <div className="collapse" id="publicRelationsBody">
                    <div className="menu-body">
                        <div className="stuff-collapse-body row no-gutters">
                            <div className="col-md-6">
                                <ul>
                                    <li>Strategic PR Planning &amp; Positioning</li>
                                    <li>Press Kits &amp; Media Relations</li>
                                    <li>Editorial Writing &amp; Pitching </li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul>
                                    <li>Media Tours &amp; Speaker Training</li>
                                    <li>Thought Leadership &amp; Content Development</li>
                                    <li>PR Measurement &amp; ROI Reporting</li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <p>In this world of instant communication and message saturation, it’s crucial to not only take control of your message before someone else does, but also differentiate amidst competitive red ocean forces. That’s where we come in. Merit Mile PR is results-driven. We shape and deliver press coverage, not promises. Ask us how we do it.</p>
                                <a href="/case-studies/equifax"><div className="btn btn-white-outline">View Case Study</div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
      </>
    );
}

function CollapseSocialMedia() {
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);
  
    return (
      <>
        <div className="itemWrapperDiv">
            <hr />
            <div
                onClick={() => {
                    setOpen(!open);
                    setActive(!active);
                }}
                aria-controls="socialMediaBody"
                aria-expanded={open}
                data-active={active}
                className="stuffItem col-12"
                id="SocialMediaItem">
                <img src={iconSocial} alt="" />
                <div className="title">Social Media</div>
                <div className="body">Shape the attitudes and create influencers while generating awareness among targeted decision makers</div>
                <div className="btn btn-expand"></div>
            </div>
            <Collapse in={open}>
                <div className="collapse" id="socialMediaBody">
                    <div className="menu-body">
                        <div className="stuff-collapse-body row no-gutters">
                            <div className="col-md-6">
                                <ul>
                                    <li>Strategy Development &amp; Design Set-up</li>
                                    <li>Content/Cadence Planning &amp; Presence Building</li>
                                    <li>Integrated Social Media &amp; PPC Campaigns</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul>
                                    <li>Editorial Mapping &amp; Audience Building</li>
                                    <li>Social Media Surveys, Polling &amp; Research Reporting</li>
                                    <li>End-to-End Execution &amp; Reporting</li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <p>The impact of social media cannot be understated. Social Media is not just a tactical marketing channel, it’s an influential path-to-purchase for nearly every consumer and B2B transaction in the real—not only digital—world. Each social media platform has strengths and limitations – which have power to affect the opinions of your decision makers? Ask Merit Mile.</p>
                                <a href="/case-studies/hpe"><div className="btn btn-white-outline">View Case Study</div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
      </>
    );
}

function CollapseResearch() {
    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(false);
  
    return (
      <>
        <div className="itemWrapperDiv">
            <hr />
            <div
                onClick={() => {
                    setOpen(!open);
                    setActive(!active);
                }}
                aria-controls="researchBody"
                aria-expanded={open}
                data-active={active}
                className="stuffItem col-12"
                id="ResearchItem">
                <img src={iconResearch} alt="" />
                <div className="title">Research</div>
                <div className="body">Reveal unknown opportunities and validate existing strategies through customized and integrated research campaigns</div>
                <div className="btn btn-expand"></div>
            </div>
            <Collapse in={open}>
                <div className="collapse" id="researchBody">
                    <div className="menu-body">
                        <div className="stuff-collapse-body row no-gutters">
                            <div className="col-md-6">
                                <ul>
                                    <li>Qualitative and Quantitative Market Research</li>
                                    <li>Consumer &amp; B2B Market Research</li>
                                    <li>Competitive Positioning &amp; Industry Surveys</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul>
                                    <li>Focus Groups, Interviews, Polls &amp; Onsite Surveys</li>
                                    <li>Reports, Presentations &amp; White Paper Development</li>
                                    <li>Project Design, Analysis &amp; Go/No Go Recommendations</li>
                                </ul>
                            </div>
                            <div className="col-12">
                                <p>You can learn a lot from us. Does your brand, product or service meet a need or solve a problem? If yes, do you have the data to back up your claims? We have methods to measure market readiness, customer satisfaction and brand loyalty. We can identify, analyze and report consumer and business behaviors so you can act on insight, not intuition.</p>
                                <a href="/case-studies/black-book"><div className="btn btn-white-outline">View Case Study</div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
      </>
    );
}

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <SEO 
            title="Merit Mile | Advertising, PR &amp; Interactive Agency | Boca Raton, FL" 
            description="Merit Mile is an award-winning Advertising, PR and Interactive agency based in Boca Raton, FL and serving clients globally. Contact us today to get started."
            ogimg="https://www.meritmile.com/uploads/2020/01/home-ss.jpg" 
            link="https://www.meritmile.com/"
            />
        <div className="home-wrapper">
            <Video />
            <SlideShow />
            <div className="container who-wrapper" id="about">
                <div className="row no-gutters">
                    <div className="col-12 copy">
                        <h2>Just Who Do We Think We Are?</h2>
                        <p>We're laser-focused problem solvers – a collective of subject matter experts, artists, technologists and dreamers – that’s who. We continually challenge the status quo and deliver results through hard work, expertise and innovation. (…plus occasional Friday afternoon cook-outs)</p>
                        <p>Explore the following case studies to learn more about us, or reach out now and tell us about you.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0 work-wrapper" id="work">
                <div className="row">
                    {workItems.map((e,i) => {
                        if(i%2 === 0) {
                            return(
                                <div key={i} className="col-md-6 pl-md-0 pr-md-1">
                                    <WorkItem url={workItems[i].url} name={workItems[i].name} type={workItems[i].type} image={workItems[i].image}/>
                                </div>
                            )
                        } else {
                            return(
                                <div key={i} className="col-md-6 pl-md-0 pr-md-0">
                                    <WorkItem url={workItems[i].url} name={workItems[i].name} type={workItems[i].type} image={workItems[i].image}/>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
            <div className="container p-0 allcase">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <a href="/case-studies/" className="view-case">View All Case Studies</a>
                    </div>
                </div>
            </div>
            <div className="container stuff-wrapper" id="services">
                <div className="row">
                    <h2>Stuff We're Really Good At</h2>
                    <CollapseAdvertising />
                    <CollapseInteractive />
                    <CollapsePublicRelations />
                    <CollapseSocialMedia />
                    <CollapseResearch />
                </div>
            </div>
            <div className="container-fluid p-0 brand-wrapper" id="clients">
                <h2>Our Brand Partners</h2>
                <div className="brand top" style={ {backgroundImage: `url(${brandTop})`} }>&nbsp;</div>
                <div className="brand bottom" style={ {backgroundImage: `url(${brandBottom})`} }>&nbsp;</div>
            </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage